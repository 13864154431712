.loading-screen {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);

    .animation {
        position: relative;

        &:after {
            display: block;
            width: 64px;
            height: 64px;
            margin: 8px;
            content: " ";
            animation: loading-full-screen 1.5s linear infinite;
            border: 6px solid #667eea;
            border-color: $color-red-60 transparent $color-red-60 transparent;
            border-radius: 50%;
        }

        @keyframes loading-full-screen {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
}

.loading-screen-full {
    width: 100vw; // Full viewport width
    height: 100vh; // Full viewport height
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}
