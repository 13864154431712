.product-detail-overview {
    @screen laptop {
        padding: 0 32px 40px;
        max-width: 1320px;
        margin: 0 auto;
    }
}

.product-overview {
    background-color: $color-black-10;
    padding: 20px 16px 32px 16px;

    @screen tablet {
        padding: 24px 24px 22px 24px;
    }

    &-table {
        @screen tablet {
            column-count: 2;
            column-gap: 16px;
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        gap: 24px;
        border-bottom: 1px solid $color-black-30;
        margin-bottom: 22px;
        padding-bottom: 24px;

        @screen tablet {
            flex-direction: row;
            column-gap: 16px;
            padding-bottom: 18px;

            .product-overview-icon,
            .product-overview-file {
                flex: 1;
            }
        }
    }

    &-icon {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .product-overview-item-icon {
            width: 40px;
        }
    }

    &-file {
        .product-overview-file {
            display: flex;
        }
    }

    .product-overview-item {
        font-size: 14px;
        line-height: 20px;

        @screen tablet {
            font-size: 16px;
            line-height: 36px;
        }
    }

    .product-overview-text {
        margin-bottom: 16px;

        &:last-of-type {
            margin-bottom: 0;
        }

        @screen tablet {
            margin-bottom: 0;
            word-break: auto-phrase;
        }

        .product-overview-headline {
            @screen tablet {
                margin-right: 10px;
                flex: 0 0 40%;
            }
        }
    }
}
