// Default catwalk styles
@import "@frontastic/theme-boost/src/scss/app.scss";

//BASIS

@import "variables";
@import "custom.properties";
@import "mixin/common";

//MIXINS

@import "mixin/link";
@import "mixin/transition";
@import "mixin/transform";
@import "mixin/lineclamp";

//SETTINGS

@import "shame";
@import "settings/settings.document";
@import "settings/settings.typography";
@import "settings/settings.scrollbars";
@import "settings/settings.cookie";

// LIBS
@import "libs/swiper/swiper";
@import "libs/swiper/navigation";
@import "libs/slick/slick";
@import "libs/slick/slick-theme";
@import "libs/lightGallery/light-gallery";

// OBJECTS

@import "objects/objects.grid";
@import "objects/objects.block";

// ELEMENTS

@import "elements/button";
@import "elements/radio";
@import "elements/input";
@import "elements/badge";
@import "elements/progress-bar";
@import "elements/modal";
@import "elements/select";
@import "elements/badge-count";
@import "elements/text-blocks";
@import "elements/table";
@import "elements/images";
@import "elements/blockquote";

// COMPONENTS

// General
@import "components/component.page-body";
@import "components/component.buttons";
@import "components/component.page-head";
@import "components/component.search";
@import "components/component.page-head.mobile";
@import "components/component.page-foot";
@import "components/component.form.mailjetnewsletter";
@import "components/component.form.newsletter";
@import "components/component.small.tile.slider";
@import "components/component.brand-slider";
@import "components/component.advatages";
@import "components/component.teaser";
@import "components/component.textimage";
@import "components/component.hero.banner";
@import "components/component.video.teaser";
@import "components/component.markdown";
@import "components/component.banner.element";
@import "components/component.banner.tile";
@import "components/component.product.teaser";
@import "components/component.product.list";
@import "components/component.image.container";
@import "components/component.branchmap";
@import "components/component.account";
@import "components/component.alerts";
@import "components/component.page-checkout";
@import "components/component.accordion";
@import "components/component.blog.post";
@import "components/component.team-gallery";
@import "components/component.store-detail";
@import "components/component.store-detail-header";
@import "components/component.sweepstakes.form";
@import "components/component.event-teaser-slider";
@import "components/component.minimal.header";
@import "components/component.minimal.footer";
@import "components/component-blog-article-element";
@import "components/component.blog-category";
@import "components/component.storefinder";
@import "components/component.error-message-product";
@import "components/component.filter";
@import "components/component.filter.algolia";
@import "components/component.product-article";
@import "components/component.breadcrumb";
@import "components/component.category-layer";
@import "components/component.product.info";
@import "components/component.product.review";
@import "components/component.cart";
@import "components/component.mini.cart";
@import "components/component.blog.title";
@import "components/component.product.tiles";
@import "components/component.contact.form";
@import "components/component.brand-list";
@import "components/component.blog-content-description";
@import "components/component-simple-tile";
@import "components/component.trusted-badge";
@import "components/component.product-category-tile";
@import "components/component.confirmation-register";
@import "components/component.notification";
@import "components/component.tile";
@import "components/component.menu.algolia";
@import "components/component.sortby.algolia";
@import "components/component.store.finder";
@import "components/component.store.time";
@import "components/component.product-attributes";
@import "components/component.mailjet";
@import "components/component.product.campaign";
@import "components/component.offcanvas.store";
@import "components/component.product.overview";
@import "components/component-gallery-masonry";
@import "components/component-gallery-grid";
@import "components/component-gallery-slider";

// UTILITIES

@import "utilities/custom.scrollbar.horizontal";
@import "utilities/hover.effect";
@import "utilities/keyframes";
@import "utilities/dotted.bar";
@import "utilities/loading.full.screen";

body {
    transition-property: opacity, transform, filter, background;
    transition-timing-function: ease-in-out;
    transition-duration: 500ms;
}

// .page-transition {
//     opacity: 0;
//     background: black;
//     transform: translateY(40px);
//     filter: blur(12px);
// }

.fullscreen-loading {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid white;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
