.account {
    transform: scale3d(1, .95, 1);
    transform-origin: 50% 0;
    opacity: 0;
    animation: calc(#{$transition-duration} * 5) $transition-ease forwards scaleAndfadeIn;

    &--login {
        &-btn-social {
            @screen desktop {
                // width: calc(50% - 8px) !important;
            }

            + span {
                transition: unset !important;
                opacity: 1 !important;
            }
        }

        .btn-back {
            transform: translate3d(0, -4px, 0);

            @screen desktop {
                transform: translate3d(0, -6px, 0);
            }
        }
    }

    &--password-reveal {
        right: 0;
    }

    &--welcome {
        line-height: 24px;
        font-family: $ff-sport2000;
        font-size: 24px;
    }

    &--headline {
        margin-top: 45px;
        font-family: $ff-sport2000;
    }

    &--delete-button,
    &--edit-button,
    &--orderview-button {
        text-transform: none;
        text-decoration: underline;
        line-height: 20px;
        letter-spacing: 0;
        font-size: 14px;
        font-weight: 500;

        svg {
            margin-right: 6px;
            width: 20px;
            height: 20px;
        }
    }

    &--orderview-button {
        .tooltip {
            position: relative;
            opacity: 0;
            margin-left: 20px;
            border-radius: 5px;
            background: $color-black-20;
            padding: 0px 15px;
            color: $color-black-50;
            font-size: 12px;

            &:before {
                position: absolute;
                top: 5px;
                left: -8px;
                transform: rotate(90deg);
                transition: calc(#{$transition-duration} * 5) transform $transition-ease;
                margin-left: -4px;
                border-top: 10px solid $color-black-20;
                border-right: 10px solid transparent;
                border-left: 10px solid transparent;
                content: '';
            }
        }

        &:disabled {
            cursor: not-allowed;
            color: $color-black-50;

            svg {
                &,
                * {
                    fill: $color-black-50;
                }
            }

            &:hover,
            &:active {
                .tooltip {
                    opacity: 1;
                }
            }
        }
    }

    &--save-button,
    &--address-button {
        span {
            margin: auto;
        }
    }

    &--address-button {
        top: -45px;
        right: 0;
    }

    &--back-button {
        justify-content: left;
        margin-top: 28px;
        background: $color-sport-white;
        padding: 18px 0 20px;
        text-transform: capitalize;
        line-height: 18px;
        font-family: $ff-sport2000;
        font-size: 18px;

        svg {
            margin: 0 16px;
            width: 20px;
            height: 20px;
        }
    }

    &--detail-overview {
        background: $color-white-100;
        padding: 24px 0;

        &-list {
            border-bottom: 1px solid $color-black-30;
            padding-bottom: 32px;
        }

        @screen tablet {
            padding: 0 0 24px;
        }
    }

    &--order-overview {
        &-item {
            padding: 16px;

            @screen tablet {
                border-bottom: 1px solid $color-black-30;
                padding: 0 0 16px;
            }

            .order-overview-date {
                color: $color-black-60;
                font-size: 14px;
            }
        }
    }

    &--no-order {
        margin-top: 12px;
        background: $color-white-100;

        @screen tablet {
            margin-top: 0;
        }

        &-button {
            svg {
                margin: 0 auto 20px;
                width: 40px;
                height: 40px;
            }

            span {
                font-weight: 700;
            }
        }
    }

    &--wrapper {
        .account-addresses {
            margin-top: 12px;
            padding: 24px 0;

            @screen tablet {
                padding: 0 0 24px;
            }

            .button-add-address {
                border-bottom: 1px solid $color-black-30;
                padding-bottom: 20px;
            }

            .account-address-card {
                border-bottom: 1px solid $color-black-30;

                .account-address-item {
                    line-height: 24px;
                    font-size: 16px;

                    .account-default-addresses {
                        margin-top: 20px;

                        .account-default-address {
                            color: $color-black-60;

                            svg {
                                &,
                                * {
                                    fill: $color-black-60;
                                }
                            }
                        }
                    }
                }
            }

            .empty-list-wrapper {
                padding: 40px;

                @screen laptop {
                    padding: 50px 40px;
                }

                .location-empty-icon {
                    svg {
                        margin: 0 auto 20px auto;
                    }
                }
            }
        }

        .account--panel {
            @screen tablet {
                margin: 0;
                justify-content: space-between;
            }

            > div:empty {
                display: none;
            }

            &-title {
                margin-bottom: 0 !important;
                font-family: $ff-sport2000;
            }

            &-desktop {
                background: $color-sport-white;
                padding: 32px 0 24px;

                @screen tablet {
                    background: transparent;
                    padding: 0;
                    flex: 0 0 40%;
                    max-width: 40%;
                }

                @screen desktop {
                    flex: 0 0 35%;
                    max-width: 35%;
                }

                &-left {
                    background: $color-sport-white;
                    padding: 24px 0;
                }

                &-right {
                    flex: 0 0 55%;
                    max-width: 55%;

                    @screen desktop {
                        flex: 0 0 60%;
                        max-width: 60%;
                    }
                }
            }

            &--menu {
                border-bottom: 1px solid $color-black-30;
                line-height: 18px;
                font-family: $ff-sport2000;
                font-size: 18px;

                &-selected {
                    background: $color-black-10;
                }
            }
        }

        .selected-order-item {
            color: $color-black-80;
        }

        .VAT-text {
            color: $color-black-70;
        }

        .plain-modal {
            @screen tablet {
                width: 650px;
                max-width: 650px;

                .plain-modal-content {
                    overflow-x: hidden;
                }
            }
        }
    }

    &-wishlist {
        .wishlist-detail-menu {
            display: flex;
            justify-content: space-between;

            &-count {
                line-height: 56px;
            }
        }

        .empty-wishlist {
            &-icon {
                margin: auto;
            }
        }

        .wishlist-detail-content {
            .product-teaser {
                .slider {
                    .swiper-button-next {
                        right: -16px !important;

                        @screen tablet {
                            right: 0 !important;
                        }

                        @screen laptop {
                            right: -16px !important;
                        }
                    }

                    .swiper-button-prev {
                        left: -16px !important;

                        @screen tablet {
                            left: 0 !important;
                        }

                        @screen laptop {
                            left: -16px !important;
                        }
                    }
                }
            }
        }

        &-mobile {
            position: fixed;
            right: 16px;
            bottom: 80px;
            z-index: 10;

            .badge-wrapper {
                border-radius: 5px;
                background-color: $color-black-80;
                min-height: 50px;
            }

            @screen tablet {
                left: 16px;
                right: auto;
            }

            @screen laptop {
                display: none;
            }
        }
    }
}

.s-node--category {
    .account-wishlist-mobile {
        bottom: 120px;
    }
}
